import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Dropdown from './dropdown';

class FormField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            valueValidation: false
        }
    }

    handleInputValue(e) {
        const value = e.target.value;
        const files = e.target.files;

        if (value || files) {
            this.setState({
                valueValidation: true
            });
        } else {
            this.setState({
                valueValidation: false
            });
        }

        this.props.onValueChange(e);
    }

    renderInputType() {
        const fieldClasses = classNames({
            'main-form__field': true,
            'is-invalid': this.props.validationError,
            'has-value': this.state.valueValidation
        });

        switch(this.props.inputType) {
            case 'input':
                return (
                    <React.Fragment>
                        <input
                            type="text"
                            id={this.props.name}
                            name={this.props.name}
                            className={fieldClasses}
                            value={this.props.value}
                            onChange={(event) => this.handleInputValue(event)}
                        />
                        <label
                            htmlFor={this.props.name}
                            className="main-form__label"
                        >
                            {this.props.label} {this.props.emoji ? String.fromCodePoint(this.props.emoji) : ''}
                        </label>
                        <span className='main-form__field-border'></span>
                    </React.Fragment>
                )
            case 'textarea':
                return (
                    <React.Fragment>
                        <textarea
                            rows="6"
                            id={this.props.name}
                            name={this.props.name}
                            className={fieldClasses}
                            value={this.props.message}
                            onChange={(event) => this.handleInputValue(event)}
                        ></textarea>
                        <label
                            htmlFor={this.props.name}
                            className="main-form__label">
                                {this.props.label}
                        </label>
                        <span className="main-form__field-border"></span>
                    </React.Fragment>
                )
            case 'dropdown':
                return (
                    <Dropdown
                        label={this.props.label}
                        options={this.props.options}
                        onSelectChange={(value, name) => this.props.onSelectChange(value, name)}
                        name={this.props.name}
                    />
                )
            case 'file':
                return (
                    <React.Fragment>
                        <label
                            className="main-form__label main-form__label--static"
                        >
                            {this.props.label}
                        </label>
                        <input
                            type="file"
                            className="main-form__field main-form__field--file"
                            name="file_attach[]"
                            onChange={(event) => this.handleInputValue(event)}
                            multiple
                        />
                        <small
                            name="file_attach[]Help"
                            className="main-form__small">
                                {this.props.conditions.map((item, index) => (
                                    <div key={index}>
                                        *{item}
                                    </div>
                                ))}
                        </small>
                    </React.Fragment>
                )
        }
    }

    render() {
        return (
            this.renderInputType()
        )
    }
}

export default FormField;

FormField.propTypes = {
    onValueChange: PropTypes.func,
    validationError: PropTypes.bool,
    inputType: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    message: PropTypes.string
}