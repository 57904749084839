import React from 'react';
import { Helmet } from 'react-helmet';
import { CSSTransitionGroup } from 'react-transition-group';
import dictionary from '../shared/dictionary';

const NotFound = () => {
	const pageTitle = dictionary.PAGES.NOT_FOUND.TITLE;
	const pageText = dictionary.PAGES.NOT_FOUND.TEXT;

	return (
		<React.Fragment>
			<Helmet>
				<title>{pageTitle + dictionary.PAGES.PREFIX}</title>
			</Helmet>
			<CSSTransitionGroup
				transitionName="location-cards"
				transitionAppear={true}
				transitionAppearTimeout={400}
				transitionEnterTimeout={400}
				transitionLeaveTimeout={400}
				className="transition-wrapper"
			>
				<div className="container container--center-v">
                    <h2 className="text-center">{pageText}</h2>
				</div>
			</CSSTransitionGroup>
		</React.Fragment>
	)
}

export default NotFound;