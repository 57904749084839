import React from 'react';

const SliderBackground = () => (
    <div className="slider-bg">
        <div className="swiper-container">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <div className="slider__img slider__img--1 slider-bg__img"></div>
                </div>
            </div>
        </div>
    </div>
);

export default SliderBackground;