import React from 'react';
import PropTypes from 'prop-types';
import breakpointWidths from '../shared/breakpoints';

class GalleryItem extends React.Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();

        this.state = {
            isLoaded: false,
            customSlideStyles: {}
        }
    }

    handleImageLoaded() {
        this.setState({
            isLoaded: true
        }, () => {
            const imgWidth = this.imgRef.current.offsetWidth;

            let inlineStyleAttr = {
                style: {width: `${imgWidth}px` }
            }

            if (window.innerWidth > breakpointWidths.sm) {
                this.setState({
                    customSlideStyles: inlineStyleAttr
                })
            }
        })
    }

    render() {
        let customSlideStyles = this.state.customSlideStyles

        return (
            <div
                {...customSlideStyles}
                className="splide__slide"
            >
                <div className="gallery-item">
                    <img
                        src={this.props.photoUrl}
                        className="gallery-item__img"
                        onLoad={event => this.handleImageLoaded(event)}
                        ref={this.imgRef}
                        data-splide-lazy={this.props.photoUrl}
                    />
                    {!this.state.isLoaded &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default GalleryItem;

GalleryItem.propTypes = {
    photoUrl: PropTypes.string
}