import React from "react";
import CommisionListItem from './commission-list-item';

type Props = {
    selectedInnerTab: [{ key: string }]
};

const CommissionInnerTabsContent = (props: Props) => (
    <ul className="commissions__list">
        {props.selectedInnerTab.map((item, index) =>
            <CommisionListItem
                listItemContent={item}
                key={index}
            ></CommisionListItem>
        )}
    </ul>
);

export default CommissionInnerTabsContent;