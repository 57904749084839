import React from 'react';
import classNames from 'classnames';
import FormField from '../components/form-field';
import PropTypes from 'prop-types';
import axios from 'axios';
import FormData from 'form-data';
import dictionary from '../shared/dictionary';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                name: '',
                email: '',
                message: ''
            },
            formErrors: {
                name: '',
                email: '',
                message: ''
            },
            nameValid: false,
            emailValid: false,
            messageValid: false,
            formValid: false,
            validationError: false,
            validationErrorMessage: dictionary.FORM.VALIDATION_ERROR_MESSAGE,
            formSent: false
        }
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [ name ]: value
            },
        }), () => {
            this.validateField(name, value)
        });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameValid = this.state.nameValid;
        let emailValid = this.state.emailValid;
        let messageValid = this.state.messageValid;

        switch(fieldName) {
            case 'name':
                nameValid = value.length >= 3;
                fieldValidationErrors.name = nameValid ? '' : ' is too short';
                break;
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : ' is invalid';
                break;
            case 'message':
                messageValid = value.length >= 5;
                fieldValidationErrors.message = messageValid ? '' : ' is too short';
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameValid: nameValid,
            emailValid: emailValid,
            messageValid: messageValid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.nameValid && this.state.emailValid && this.state.messageValid
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.state.formValid) {
            this.setState({
                validationError: true
            })

            return;
        }

        if ('NodeList' in window) {
            if (!NodeList.prototype.each && NodeList.prototype.forEach) {
                NodeList.prototype.each = NodeList.prototype.forEach;
            }
        }

        grecaptcha.ready(() => {
            grecaptcha.execute('6LdeB1MhAAAAAKfSh8KBFOWzOZclcbdnpWAUJ_TB', {action: 'contactForm'}).then((token) => {
                gRecaptchaResponse.value = token
                let contactFormData = new FormData();

                Object.keys(this.state.formData).forEach((key) => {
                    contactFormData.append(key, this.state.formData[key])
                });

                contactFormData.append('gRecaptchaResponse', token);

                axios({
                    method: 'post',
                    url: '/contact-mail.php',
                    data: contactFormData,
                    headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then((response) => {
                        console.log('success: ', response);
                        this.setState({ formSent: true });
                    })
                    .catch((response) => {
                        console.log('error: ', response);
                });
            });
        });
    }

    render() {
        const validationErrorClasses = classNames({
            'main-form__validation-message': true,
            'is-visible': this.state.validationError
        });

        return (
            <>
            <div className="main-form main-form--contact">
                {!this.state.formSent ? (
                    <>
                        <form
                            id="contactForm"
                        >
                            <div className="main-form__field-container">
                                <FormField
                                    validationError={this.state.validationError}
                                    value={this.state.formData.name || ''}
                                    label={this.props.nameLabel}
                                    onValueChange={(event) => this.handleUserInput(event)}
                                    name='name'
                                    inputType='input'
                                />
                            </div>
                            <div className="main-form__field-container">
                                <FormField
                                    validationError={this.state.validationError}
                                    value={this.state.formData.email || ''}
                                    label={this.props.emailLabel}
                                    onValueChange={(event) => this.handleUserInput(event)}
                                    name='email'
                                    inputType='input'
                                />
                            </div>
                            <div className="main-form__field-container">
                                <FormField
                                    validationError={this.state.validationError}
                                    value={this.state.formData.message || ''}
                                    label={this.props.messageLabel}
                                    onValueChange={(event) => this.handleUserInput(event)}
                                    name='message'
                                    inputType='textarea'
                                />
                            </div>
                        </form>
                    </>
                ) : (
                    <p>{dictionary.FORM.VALIDATION_SUCCESS_MESSAGE}</p>
                )}
            </div>
            {!this.state.formSent && (
                <div className="main-form__submit-container">
                    <button
                        type="submit"
                        className="main-form__submit-btn"
                        onClick={event => this.handleSubmit(event)}
                    >
                        {this.props.submitBtnLabel}
                    </button>
                    <span
                        className={validationErrorClasses}
                    >
                        {this.state.validationErrorMessage}
                    </span>
                    <input type="hidden" id="gRecaptchaResponse" name="gRecaptchaResponse" />
                </div>
            )}
            </>
        )
    }
}

export default ContactForm;

ContactForm.propTypes = {
    nameLabel: PropTypes.string,
    emailLabel: PropTypes.string,
    messageLabel: PropTypes.string,
    submitBtnLabel: PropTypes.string
}