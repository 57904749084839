import React from 'react';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import Header from '../modules/header';
import Footer from '../modules/footer';
import Home from '../pages/home.js';
import About from '../pages/about.js';
import Commissions from '../pages/commissions.js';
import Gallery from '../pages/gallery.js';
import Contact from '../pages/contact.js';
import NotFound from '../pages/404.js';
import '../../scss/main.scss';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import dictionary from '../shared/dictionary';

class MainLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLineVisible: false
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.location !== this.props.location) {
            this.setState({
                isLineVisible: true
            })
        }
    }

    componentWillReceiveProps() {
        this.setState({
            isLineVisible: false
        })
    }

    render() {
        let currentPageClasses;
        if (this.props.location.pathname === '/') {
            currentPageClasses = `current-page-content home`
        } else {
            currentPageClasses = `current-page-content ${this.props.location.pathname.substr(1)}`
        }

        return (
            <React.Fragment>
                <Header
                    isLineVisible={this.state.isLineVisible}
                />
                <section className={currentPageClasses}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/about" component={About} />
                        <Route path="/commissions" component={Commissions} />
                        <Route path="/gallery" component={Gallery} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/404" component={NotFound} />
                        <Redirect to="/404" />
                    </Switch>
                    <CookieConsent
                        location="bottom"
                        buttonText={dictionary.COOKIE_CONSENT.BUTTON_TEXT}
                        disableStyles={true}
                        disableButtonStyles={true}
                        expires={150}
                        buttonClasses="cookie-consent__button"
                        containerClasses="cookie-consent__container"
                        contentClasses="cookie-consent__content"
                    >
                        {dictionary.COOKIE_CONSENT.TEXT}
                    </CookieConsent>
                </section>
                <Footer
                    isLineVisible={this.state.isLineVisible}
                />
            </React.Fragment>
        )
    }
}

export default withRouter(MainLayout);

MainLayout.propTypes = {
    location: PropTypes.object
}