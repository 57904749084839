import React from 'react';
import classNames from 'classnames';

class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            display: false,
            isOpen: false,
            isListElementRendered: false,
            isArrowHidden: false,
            valueValidation: false,
            selectedItemText: ''
        };
    }

    handleStateOpen() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    onSelectBlur(e) {
        this.setState({ isOpen: false });

        const dd = e.currentTarget.querySelector('.main-form__dropdown-menu');

        dd.style.maxHeight = null;
    }

    onSelectClick(e) {
        const dropdownMenu = e.currentTarget.parentElement.parentElement.querySelector('.main-form__dropdown-menu');

        new Promise((res, rej) => {
            res(this.handleStateOpen());
        }).then(() => {
            dropdownMenu.style.maxHeight = this.state.isOpen ? dropdownMenu.scrollHeight + "px" : null;
        });

        if (this.props.options.length === 0) {
            this.setState({ isListElementRendered: true });
        } else {
            this.setState({ isListElementRendered: false });
        }
    }

    onSelectChange(e, val) {
        const name = e.target.getAttribute('name');

        this.setState({
            isOpen: false,
            selectedItemText: val,
            valueValidation: true
        }, () => this.props.onSelectChange(this.state.selectedItemText, name));

        const dd = e.target.closest('.main-form__dropdown-menu');
        dd.style.maxHeight = null;
    }

    renderLabel() {
        return (
            <span className="main-form__label">
                { this.props.label }
            </span>
        );
    }

    renderSelect() {
        const selectClasses = classNames({
            'main-form__dropdown': true,
            'has-value': this.state.valueValidation,
            'is-open': this.state.isOpen
        });

        const listClasses = classNames({
            'has-error': this.state.hasError,
        });

        const selectedItemText = this.state.selectedItemText;

        return (
            <dl
                className={selectClasses}
                onBlur={event => this.onSelectBlur(event)}
            >
                <dt>
                    <button
                        className="main-form__dropdown-btn"
                        type="button"
                        onClick={event => this.onSelectClick(event)}
                        dangerouslySetInnerHTML={{__html: selectedItemText }}
                    ></button>
                    <span
                        className="main-form__dropdown-arrow"
                    >
                        <span
                            className="fas fa-sort-down"
                        ></span>
                    </span>
                </dt>
                <dd
                    className="main-form__dropdown-menu"
                >
                    <ul
                        className={listClasses}
                    >
                        {this.state.isListElementRendered &&
                        <li data-null>---</li>
                        }
                        {this.props.options.map((option, key) => (
                        <li
                            value={option}
                            key={key}
                            className="main-form__list-item"
                            onMouseDown={event => this.onSelectChange(event, option)}
                            name={this.props.name}
                        >
                            {option}
                        </li>))}
                    </ul>
                </dd>
            </dl>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderSelect()}
                {this.renderLabel()}
            </React.Fragment>
        )
    }
}

export default Dropdown;