import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import CommissionForm from '../modules/commission-form';
import { CSSTransitionGroup } from 'react-transition-group';
import SimpleBar from 'simplebar-react';
import CommissionTabsContent from '../components/commission-tabs-content';
import CommissionInnerTabsContent from '../components/commissions-inner-tabs-content';
import CommissionInnerTabs from '../components/commissions-inner-tabs';
import { isDesktopDevice } from '../shared/device-check';
import dictionary from '../shared/dictionary';

class Commissions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: dictionary.PAGES.COMMISSIONS.TITLE,
            selected: 0,
            selectedInner: 0,
            commissionTabs: dictionary.PAGES.COMMISSIONS.TABS,
            commissionInnerTabs: dictionary.PAGES.COMMISSIONS.INNER_TABS,
            serviceTabContent: dictionary.PAGES.COMMISSIONS.SERVICE_TAB,
            legalDisclaimerTabContent: dictionary.PAGES.COMMISSIONS.LEGAL_DISCLAIMER_TAB,
            workingSystemTabContent: dictionary.PAGES.COMMISSIONS.WORKING_SYSTEM_TAB,
            shippingTabContent: dictionary.PAGES.COMMISSIONS.SHIPPING_TAB,
            commissionFormLabels: {
				submitBtn: dictionary.FORM.SEND_LABEL
            }
        }
    }

    handleTabChange(e, index) {
        this.setState({
            selected: index
        });
    }

    handleContentChange() {
        let serviceTabContent = this.state.serviceTabContent;
        let selectedTab;

        switch(this.state.selected) {
            case 0:
                return this.handleInnerContentChange();
                break;
            case 1:
                selectedTab = serviceTabContent;
                break;
            case 2:
                return (
                    <CommissionForm
                        submitBtnLabel={this.state.commissionFormLabels.submitBtn}
                    ></CommissionForm>
                )
                break;
        }

        if (isDesktopDevice()) {
            return (
                <SimpleBar style={{ maxHeight: `${55}vh` }}>
                    <CommissionTabsContent
                        selectedTab={selectedTab}
                    />
                </SimpleBar>
            )
        } else {
            return (
                <CommissionTabsContent
                    selectedTab={selectedTab}
                />
            )
        }
    }

    handleInnerTabChange(e, index) {
        this.setState({
            selectedInner: index
        });
    }

    handleInnerContentChange() {
        let legalDisclaimerTabContent = this.state.legalDisclaimerTabContent;
        let workingSystemTabContent = this.state.workingSystemTabContent;
        let shippingTabContent = this.state.shippingTabContent;
        let selectedInnerTab;

        switch(this.state.selectedInner) {
            case 0:
                selectedInnerTab = legalDisclaimerTabContent;
                break;
            case 1:
                selectedInnerTab = workingSystemTabContent;
                break;
            case 2:
                selectedInnerTab = shippingTabContent;
                break;
        }

        if (isDesktopDevice()) {
            return (
                <SimpleBar style={{ maxHeight: `${55}vh` }}>
                    <CommissionInnerTabsContent
                        selectedInnerTab={selectedInnerTab}
                    />
                </SimpleBar>
            )
        } else {
            return (
                <CommissionInnerTabsContent
                    selectedInnerTab={selectedInnerTab}
                />
            )
        }
    }

    render() {
        let btnClasses = (activeIndex) => classNames({
            'commissions__btn': true,
            'is-active': this.state.selected == activeIndex ? true : false
        });

        let btnInnerClasses = (activeIndex, index) => classNames({
			'commissions__btn': true,
			'commissions__btn--inner': index === 0,
            'is-active': this.state.selectedInner == activeIndex ? true : false
		});

        let commissionTabs = this.state.commissionTabs;
		let commissionInnerTabs = this.state.commissionInnerTabs;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.pageTitle + dictionary.PAGES.PREFIX}</title>
                </Helmet>
                <CSSTransitionGroup
					transitionName="location-cards"
					transitionAppear={true}
					transitionAppearTimeout={400}
					transitionEnterTimeout={400}
                    transitionLeaveTimeout={400}
                    className="transition-wrapper"
				>
                    <div className="container container--center-v container--fixed-height">
                        <div className="row">
                            <div className="col-md-6 col-xl-4 offset-xl-1">
                                <h1>{this.state.pageTitle}</h1>
                                <div className="row">
                                    <div className="offset-md-1 col-md-11">
                                        <div>
                                            <ul className="commissions__tabs">
                                                {commissionTabs.map((item, index) =>
                                                    <li
                                                        className="commissions__tab"
                                                        key={index}
                                                    >
                                                        <button
                                                            className={btnClasses(index)}
                                                            onClick={e => this.handleTabChange(e, index)}
                                                        >
                                                            {item}
                                                        </button>
                                                        {index === 0 && this.state.selected === 0 &&
                                                            <ul className="commissions__tabs commissions__tabs--inner">
                                                                {commissionInnerTabs.map((item, indexInner) =>
                                                                    <CommissionInnerTabs
                                                                        key={indexInner}
                                                                        className={btnInnerClasses(indexInner, index)}
                                                                        item={item}
                                                                        handleInnerTabChange={e => this.handleInnerTabChange(e, indexInner)}
                                                                    />
                                                                )}
                                                            </ul>
                                                        }
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {this.handleContentChange()}
                            </div>
                        </div>
                    </div>
                </CSSTransitionGroup>
            </React.Fragment>
        )
    }
}

export default Commissions;