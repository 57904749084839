import React, { useContext } from "react";
import LineBlack from '../components/line-black';
import classNames from 'classnames';
import dictionary from '../shared/dictionary';
import { ThemeContext } from '../utils/theme-provider';

type FooterProps = {
    isLineVisible: boolean;
};

const Footer = (props: FooterProps) => {
    const { theme, toggleTheme } = useContext(ThemeContext);

    const setDate = () => {
        const date = new Date();

        return (
            date.getFullYear()
        )
    }

    let socialBtnClasses = (site) => classNames(
        'footer__social-icon',
        'fab',
        `footer__social-icon--${site}`,
        `fa-${site}`,
        'fa-lg'
    );

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__copy">theugliestwife &copy; {setDate()}</div>
                <ul className="footer__social">
                    {dictionary.SOCIAL_LINKS.map((item, index) =>
                        <li
                            className="footer__social-btn"
                            key={index}
                        >
                            <a
                                href={item.URL}
                                target='_blank'
                                className={socialBtnClasses(item.TITLE)}></a>
                        </li>
                    )}
                </ul>
                <button
                    className={`dark-mode fas fa-solid fa-lg ${theme === 'light' ? 'to-dark fa-moon' : 'to-light fa-sun'}`}
                    onClick={toggleTheme}
                />
                <LineBlack
                    changedClass={props.isLineVisible}
                />
            </div>
        </footer>
    );
};

export default Footer;