import React, { useEffect, useState } from 'react';
import breakpointWidths from '../shared/breakpoints';
import GalleryItem from './gallery-item';
import photoUrl1 from '../../images/photos/DSC_0053.jpg';
import photoUrl2 from '../../images/photos/DSC_0066.jpg';
import photoUrl3 from '../../images/photos/DSC_0217.jpg';
import photoUrl4 from '../../images/photos/DSC_0404.jpg';
import photoUrl5 from '../../images/photos/DSC_0422.jpg';
import photoUrl6 from '../../images/photos/DSC_0463.jpg';
import photoUrl7 from '../../images/photos/DSC_0548.jpg';
import photoUrl8 from '../../images/photos/DSC_0564.jpg';
import photoUrl9 from '../../images/photos/DSC_0622.jpg';
import photoUrl10 from '../../images/photos/DSC_0629.jpg';
import photoUrl11 from '../../images/photos/DSC_0657.jpg';
import photoUrl12 from '../../images/photos/DSC_0733.jpg';
import photoUrl13 from '../../images/photos/DSC_0931.jpg';
import photoUrl14 from '../../images/photos/DSC_0937.jpg';
import photoUrl15 from '../../images/photos/DSC_6363.jpg';
import photoUrl16 from '../../images/photos/DSC_7018.jpg';
import photoUrl17 from '../../images/photos/DSC_7059.jpg';
import photoUrl18 from '../../images/photos/DSC_7508.jpg';
import photoUrl19 from '../../images/photos/DSC_9441.jpg';
import photoUrl20 from '../../images/photos/DSC_9481.jpg';
import photoUrl21 from '../../images/photos/DSC_9845.jpg';
import { Splide } from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';


const SliderGallery = () => {
    const photosUrls = [
        photoUrl1,
        photoUrl2,
        photoUrl3,
        photoUrl4,
        photoUrl5,
        photoUrl6,
        photoUrl7,
        photoUrl8,
        photoUrl9,
        photoUrl10,
        photoUrl11,
        photoUrl12,
        photoUrl13,
        photoUrl14,
        photoUrl15,
        photoUrl16,
        photoUrl17,
        photoUrl18,
        photoUrl19,
        photoUrl20,
        photoUrl21,
    ];

    const [shuffledPhotos, setShuffledPhotos] = useState(photosUrls);

    useEffect(() => {
        if (window.innerWidth > breakpointWidths.sm) {
            new Splide('.splide', {
                type: 'loop',
                focus: 'left',
                autoWidth: true,
                keyboard: 'global',
                arrows: true,
                waitForTransition: true,
                gap: '10px',
                lazyLoad: 'sequential',
                pagination: false
            }).mount();
        }

        setShuffledPhotos(photosUrls
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
        )
    }, []);

    return (
        <div className="splide">
            <div className={`splide__track`}>
                <div className="splide__list">
                    {shuffledPhotos.map((photoUrl, index) =>
                        <GalleryItem
                            photoUrl={photoUrl}
                            key={index}
                        ></GalleryItem>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SliderGallery;