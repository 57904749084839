import React, { useEffect, useState } from "react";
type Theme = "light" | "dark";
type ThemeContext = { theme: Theme; toggleTheme: () => void };

export const ThemeContext = React.createContext<ThemeContext>(
  {} as ThemeContext
);

export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme>("light");

  useEffect(() => {
    if (localStorage.getItem("isDarkMode") === "1") {
      setTheme("dark")
    } else {
      setTheme("light")
    }
  }, []);

  useEffect(() => {
    if (theme === "light") {
      localStorage.setItem("isDarkMode", "0");
    } else {
      localStorage.setItem("isDarkMode", "1");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  document.body.className = theme;

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};