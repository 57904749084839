import React from "react";
import CommisionListItem from './commission-list-item';

type Props = {
	selectedTab: [{ key: string }]
};

const CommissionTabsContent = (props: Props) => (
	<ul className="commissions__list">
		{props.selectedTab.map((item, index) =>
			<CommisionListItem
				listItemContent={item}
				key={index}
			></CommisionListItem>
		)}
	</ul>
);

export default CommissionTabsContent;