export const isDesktopDevice = () => {
    const windowWidth = window.innerWidth;
    let isMobile = false;

    if (windowWidth >= 768) {
        isMobile = true
    } else {
        isMobile = false
    }

    return isMobile;
}

export const isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);