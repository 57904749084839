import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SliderBackground from '../components/slider-background.tsx';
import SimpleBar from 'simplebar-react';
import { CSSTransitionGroup } from 'react-transition-group';
import { isDesktopDevice } from '../shared/device-check';
import dictionary from '../shared/dictionary';

const About = () => {
	const pageTitle = dictionary.PAGES.ABOUT.TITLE;
	const pageText = dictionary.PAGES.ABOUT.TEXT;

	return (
		<>
			<Helmet>
				<title>{pageTitle + dictionary.PAGES.PREFIX}</title>
			</Helmet>
			<CSSTransitionGroup
				transitionName="location-cards"
				transitionAppear={true}
				transitionAppearTimeout={400}
				transitionEnterTimeout={400}
				transitionLeaveTimeout={400}
				className="transition-wrapper"
			>
				<div className="container container--center-v">
					<div className="row">
						<div className="col-md-6 col-xl-5 offset-xl-1">
							<h1>{pageTitle}</h1>
							<div className="row">
								<div className="offset-md-1 col-md-10">
									<div className="text-container">
										{isDesktopDevice() ? (
											<SimpleBar style={{ maxHeight: `${40}vh` }}>
												<p
													dangerouslySetInnerHTML={{ __html: pageText}}
												/>
											</SimpleBar>
										) : (
											<p
												dangerouslySetInnerHTML={{ __html: pageText}}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SliderBackground
					asNavFor={null}
					current={slider => (this.slider1 = slider)}
					speed={300}
				/>
			</CSSTransitionGroup>
		</>
	)
}

export default About;