import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SimpleBar from 'simplebar-react';
import { CSSTransitionGroup } from 'react-transition-group';
import ContactForm from '../modules/contact-form';
import qrCode from '../../images/qr-code.svg';
import classNames from 'classnames';
import dictionary from '../shared/dictionary';

const About = () => {
	const [ isMounted, setIsMounted ] = useState(false);

	const pageTitle = dictionary.PAGES.CONTACT.TITLE;
	const pageText = dictionary.PAGES.CONTACT.TEXT;
	const contactFormLabels = {
		name: dictionary.PAGES.CONTACT.FORM_CONTENT.NAME,
		email: dictionary.PAGES.CONTACT.FORM_CONTENT.EMAIL,
		message: dictionary.PAGES.CONTACT.FORM_CONTENT.MESSAGE,
		submitBtn: dictionary.FORM.SEND_LABEL
	};

	useEffect(() => {
		setIsMounted(true);
	}, []);

	const slideClasses = (side) => classNames({
		'slide-in': true,
		'slide-in--left': side === 'left',
		'slide-in--right': side === 'right',
		'is--mounted': isMounted
	});

	return (
		<>
			<Helmet>
				<title>{pageTitle + dictionary.PAGES.PREFIX}</title>
			</Helmet>
			<CSSTransitionGroup
				transitionName="location-cards"
				transitionAppear={true}
				transitionAppearTimeout={400}
				transitionEnterTimeout={400}
				transitionLeaveTimeout={400}
				className="transition-wrapper"
			>
				<div className="container container--center-v">
					<div className="row">
						<div className="col-md-6 col-xl-5 offset-xl-1">
							<div className={slideClasses('left')}>
								<h1>{pageTitle}</h1>
								<div className="row">
									<div className="offset-md-1 col-md-11">
										<div className="text-container">
											<SimpleBar style={{ maxHeight: `${55}vh` }}>
												<p>
													{pageText}
												</p>
											</SimpleBar>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="offset-md-1 col-md-11">
										<div className="qr-code">
											<img src={qrCode} alt="QR code"/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-xl-5">
							<div className={slideClasses('right')}>
								<ContactForm
									nameLabel={contactFormLabels.name}
									emailLabel={contactFormLabels.email}
									messageLabel={contactFormLabels.message}
									submitBtnLabel={contactFormLabels.submitBtn}
								/>
							</div>
						</div>
					</div>
				</div>
			</CSSTransitionGroup>
		</>
	)
}

export default About;