import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { CSSTransitionGroup } from 'react-transition-group';
import logoURL from '../../images/logo.png';
import Parallax from 'parallax-js';
import classNames from 'classnames';
import dictionary from '../shared/dictionary';
import { isIOS } from '../shared/device-check';

const Home = () => {
	const [ isMounted, setIsMounted ] = useState(false);

	const pageTitle = dictionary.PAGES.HOME.TITLE;
	const pageText = dictionary.PAGES.HOME.TEXT;

	useEffect(() => {
		setIsMounted(true);
		
		if (!isIOS) {
			new Parallax(document.querySelector('[data-parallax]'));
		}
	}, []);

	const requestMotionPermissions = () => {
		if (isIOS) {
			DeviceMotionEvent.requestPermission()
			.then(response => {
				if (response == 'granted') {
					new Parallax(document.querySelector('[data-parallax]'));
				}
			})
			.catch(console.error)
		}
	};

	const slideClasses = classNames({
		'slide-in': true,
		'slide-in--left': true,
		'is--mounted': isMounted
	});

	return (
		<div>
			<Helmet>
				<title>{pageTitle + dictionary.PAGES.PREFIX}</title>
			</Helmet>
			<CSSTransitionGroup
				transitionName="location-cards"
				transitionAppear={true}
				transitionAppearTimeout={600}
				transitionEnterTimeout={600}
				transitionLeaveTimeout={600}
				className="transition-wrapper"
			>
				<div
					className="container container--center-v"
					onClick={requestMotionPermissions}
				>
					<div className="row">
						<div className="col-md-6">
							<div className="row">
								<div className="offset-md-2 col-md-10">
									<div className={slideClasses}>
										<div
											className="text-container text-container--centered"
										>
												<h1 className="h1--main">
												<span className="color-anim">T</span>
												<span className="color-anim">H</span>
												<span className="color-anim">E</span>
												<span className="color-anim">U</span>
												<span className="color-anim">G</span>
												<span className="color-anim">L</span>
												<span className="color-anim">I</span>
												<span className="color-anim">E</span>
												<span className="color-anim">S</span>
												<span className="color-anim">T</span>
												<span className="color-anim">W</span>
												<span className="color-anim">I</span>
												<span className="color-anim">F</span>
												<span className="color-anim">E</span>
											</h1>
											<p>
												{pageText}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="slider slider-bg slider-bg--slide-in slider-bg--static-sm"
					onClick={requestMotionPermissions}
				>
					<div data-parallax>
						<div className="flex-container flex-container--center" data-depth="0.6">
							<div className="logo-container">
								<img
									src={logoURL}
								/>
							</div>
						</div>
					</div>
				</div>
			</CSSTransitionGroup>
		</div>
	)
}

export default Home;