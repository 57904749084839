import React, { useContext, useState } from "react";
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import LineBlack from '../components/line-black';
import Logo from '../components/logo';
import classNames from 'classnames';
import dictionary from '../shared/dictionary';
import { ThemeContext } from '../utils/theme-provider';

type HeaderProps = {
    isLineVisible: boolean;
};

const Header = (props: HeaderProps) => {
    const [ isMobileMenuVisible, setMobileMenuVisible ] = useState(false);

    const { theme, toggleTheme } = useContext(ThemeContext);

    const navLinks = [
        dictionary.PAGES.ABOUT.TITLE,
        dictionary.PAGES.COMMISSIONS.TITLE,
        dictionary.PAGES.GALLERY.TITLE,
        dictionary.PAGES.CONTACT.TITLE
    ]

    const handleMobileMenuDisplay = (e: any) => {
        setMobileMenuVisible(!isMobileMenuVisible);
    }

    const handleMenuHide = (e: any) => {
        setMobileMenuVisible(false);
    }

    let link = (path) => `/${path}`;
    let navClasses = classNames({
        'mobile-menu': isMobileMenuVisible
    });

    return (
        <header className="header">
            <div className="container">
                <div className={`header__logo ${window.location.pathname === "/" ? 'is-active' : ''}`}>
                    <NavLink
                        to="/"
                        onClick={e => handleMenuHide(e)}
                    >
                        <Logo
                            theme={theme}
                        />
                    </NavLink>
                </div>
                <button
                    className={`dark-mode dark-mode--header fas fa-solid fa-lg ${theme === 'light' ? 'to-dark fa-moon' : 'to-light fa-sun'}`}
                    onClick={toggleTheme}
                />
                <nav className={navClasses}>
                    <button
                        className="header__menu-btn"
                        onClick={e => handleMobileMenuDisplay(e)}
                    >
                        <span className="header__menu-btn--item"></span>
                        <span className="header__menu-btn--item"></span>
                        <span className="header__menu-btn--item"></span>
                    </button>
                    <ul className="header__navbar">
                        {navLinks.map((item, index) =>
                            <li
                                className="header__navbar-item"
                                key={index}
                            >
                                <NavLink
                                    to={link(item.toLowerCase())}
                                    className="header__navbar-link"
                                    activeClassName="header__navbar-link--active"
                                    onClick={e => handleMenuHide(e)}
                                >
                                    {item}
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </nav>
                <LineBlack
                    changedClass={props.isLineVisible}
                />
            </div>
        </header>
    )
}

export default Header;