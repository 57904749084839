import React from 'react';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
import FormField from '../components/form-field';
import camelizeString from '../shared/camelize';
import PropTypes from 'prop-types';
import axios from 'axios';
// import commissionFormData from '../../../data/commission-form.json';
import FormData from 'form-data';
import { isDesktopDevice } from '../shared/device-check';
import dictionary from '../shared/dictionary';

class CommissionForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            formErrors: {
                name: '',
                email: '',
                message: ''
            },
            nameValid: false,
            emailValid: false,
            formValid: false,
            validationError: false,
            validationErrorMessage: dictionary.FORM.VALIDATION_ERROR_MESSAGE,
            fieldsetSections: dictionary.PAGES.COMMISSIONS.FORM_CONTENT,
            fieldValues: {
                companyNameSize: '',
                gender: '',
                moodExpressionCharacter: '',
                hairEyebrowsColor: '',
                lipColor: '',
                lipFinish: '',
                blush: '',
                freckles: '',
                eyeColor: '',
                shimmer: '',
                glitterOnTheEyelidstattoos: '',
                blackEyelashes: '',
                previousFaceupRemoval: '',
                manicurePedicure: '',
                fullBodyBlushing: '',
                email: '',
                fullName: '',
                address: '',
                postcodeCity: '',
                country: '',
                phone: '',
                valueOfContentsToBeMarkedOnCustomsForms: '',
                parcelIncludes: '',
                whereDidYouFindOutAboutTheUgliestWife: '',
                'file_attach[]': {}
            },
            formSent: false
        }
    }

    handleUserInput(e) {
        const name = e.target.name;
        const files = e.target.files;
        let value;

        if (files) {
            value = e.target.files[0];
        } else {
            value = e.target.value;
        }

        this.setState((prevState) => ({
            fieldValues: {
                ...prevState.fieldValues,
                [ name ]: value
            }
        }), () => {
            this.validateField(name, value)
        });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameValid = this.state.nameValid;
        let emailValid = this.state.emailValid;

        switch(fieldName) {
            case 'fullName':
                nameValid = value.length >= 3;
                fieldValidationErrors.name = nameValid ? '' : ' is too short';
                break;
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : ' is invalid';
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameValid: nameValid,
            emailValid: emailValid,
        }, this.validateForm);
    }

    validateForm() {
        if (this.state.fieldValues['file_attach[]'] && this.state.fieldValues['file_attach[]'].size > 2000000) {
            this.setState({
                formValid: false,
                validationErrorMessage: dictionary.FORM.VALIDATION_ERROR_IMAGE_TOO_BIG
            })
        } else {
            this.setState({
                formValid: this.state.nameValid && this.state.emailValid
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.state.formValid) {
            this.setState({
                validationError: true
            })

            let commissionForm = new FormData();

            Object.keys(this.state.fieldValues).forEach((key) => {
                commissionForm.append(key, this.state.fieldValues[key])
            });

            return;
        }

        if ('NodeList' in window) {
            if (!NodeList.prototype.each && NodeList.prototype.forEach) {
                NodeList.prototype.each = NodeList.prototype.forEach;
            }
        }

        grecaptcha.ready(() => {
            grecaptcha.execute('6LdeB1MhAAAAAKfSh8KBFOWzOZclcbdnpWAUJ_TB', {action: 'commissionForm'}).then((token) => {
                gRecaptchaResponse.value = token
                let commissionForm = new FormData();

                Object.keys(this.state.fieldValues).forEach((key) => {
                    commissionForm.append(key, this.state.fieldValues[key])
                });

                commissionForm.append('gRecaptchaResponse', token);

                axios({
                    method: 'post',
                    url: '/commission-mail.php',
                    data: commissionForm,
                    dataType: 'json',
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then((response) => {
                    console.log('success: ', response);
                    this.setState({ formSent: true });
                })
                .catch((response) => {
                    console.log('error: ', response);
                });

            });
        });
    }

    onSelectChange(value, name) {
        this.setState((prevState) => ({
            fieldValues: {...prevState.fieldValues, [ name ]: value.toLowerCase() }
        }));
    }

    renderMainForm() {
        let fieldsetSections = this.state.fieldsetSections;

        return (
            <div className="main-form">
                {!this.state.formSent ? (
                    <form>
                        {(fieldsetSections).map((item, key) =>
                            <fieldset className="main-form__fieldset" key={key}>
                                <legend className="main-form__legend">{item.title}</legend>
                                {(item.fieldNames).map((item, key) =>
                                    <div className="main-form__field-container" key={key}>
                                        <FormField
                                            validationError={this.state.validationError}
                                            label={item.name}
                                            onValueChange={(event) => this.handleUserInput(event)}
                                            name={camelizeString(item.name.replace(/[\/]|&|\?/g, '')).replace(/[^\x20-\x7E]+/g, '')}
                                            inputType={item.inputType || 'input'}
                                            options={item.options}
                                            onSelectChange={(value, name) => this.onSelectChange(value, name)}
                                            conditions={item.conditions}
                                            emoji={item.emoji}
                                        />
                                    </div>
                                )}
                            </fieldset>
                        )}
                    </form>
                ) : (
                    <div className="main-form__message-sent">
                        <p>{dictionary.FORM.VALIDATION_SUCCESS_MESSAGE}</p>
                    </div>
                )}
            </div>
        )
    }

    render() {
        const validationErrorClasses = classNames({
            'main-form__validation-message': true,
            'is-visible': this.state.validationError
        });

        return (

            <>
                {isDesktopDevice() ? (
                    <SimpleBar style={{ maxHeight: `${50}vh` }}>
                        {this.renderMainForm()}
                    </SimpleBar>
                ) : (
                    this.renderMainForm()
                )}
                {!this.state.formSent &&
                    <div className="main-form__submit-container">
                        <button
                            type="submit"
                            className="main-form__submit-btn"
                            onClick={event => this.handleSubmit(event)}
                        >
                            {this.props.submitBtnLabel}
                        </button>
                        <span
                            className={validationErrorClasses}
                        >
                            {this.state.validationErrorMessage}
                        </span>
                        <input type="hidden" id="gRecaptchaResponse" name="gRecaptchaResponse" />
                    </div>
                }
            </>
        )
    }
}

export default CommissionForm;

CommissionForm.propTypes = {
    submitBtnLabel: PropTypes.string
}