import React from 'react';

type Props = {
    className: string;
    handleInnerTabChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
    item: string;
}

const CommissionsInnerTabs = (props: Props) => (
    <li
        className="commissions__tab"
    >
        <button
            className={props.className}
            onClick={e => props.handleInnerTabChange(e)}
        >
            {props.item}
        </button>
    </li>
);

export default CommissionsInnerTabs;