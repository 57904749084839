import React from 'react';
import classNames from 'classnames';

type LineBlackProps = {
    changedClass: boolean;
}

const LineBlack = (props: LineBlackProps) => {
    const lineBlackClasses = classNames({
        'line-black': true,
        'line-black--changed': props.changedClass
    });

    return (
        <div className={lineBlackClasses}></div>
    )
}

export default LineBlack;