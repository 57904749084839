import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import MainLayout from './assets/js/layouts/main.js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ThemeProvider } from "./assets/js/utils/theme-provider";

const mountNode = document.getElementById("app");
const app = (
    <BrowserRouter>
        <GoogleReCaptchaProvider
            reCaptchaKey="6LdeB1MhAAAAAKfSh8KBFOWzOZclcbdnpWAUJ_TB"
            language="en"
        >
            <ThemeProvider>
                <MainLayout />
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    </BrowserRouter>
);

if (mountNode.hasChildNodes()) {
  hydrate(app, mountNode);
} else {
  render(app, mountNode);
}