import React, { useState, useEffect } from 'react';

const Logo = (props) => {
    const colorWhite = '#ffffff';
    const colorBlack = '#000000';
    const primaryColor = '#e83393';
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        window.location.pathname === "/" ? setActive(true) : setActive(false)
    }, [window.location.pathname]);

    return (
        <React.Fragment>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="100.000000pt" height="99.000000pt" viewBox="0 0 100.000000 99.000000" preserveAspectRatio="xMidYMid meet" className="logo-svg">
                <g transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)"
                fill={`${isActive ? primaryColor : (props.theme === 'light' ? colorBlack : colorWhite)}`} stroke="none">
                    <path d="M250 908 c0 -13 -8 -18 -29 -18 -23 0 -41 -13 -82 -57 -103 -113
                    -149 -261 -128 -413 12 -85 21 -102 41 -71 15 24 33 29 24 6 -8 -23 0 -18 19
                    11 17 26 17 27 -4 21 -31 -8 -26 7 13 37 24 18 36 22 39 13 3 -6 6 0 6 14 1
                    37 58 79 106 79 30 0 36 -3 31 -16 -5 -13 -4 -14 7 -6 8 7 17 8 21 4 4 -4 6
                    -1 4 7 -2 8 7 20 19 26 13 7 20 15 16 18 -3 4 -6 43 -5 86 0 62 -4 89 -20 118
                    -14 26 -18 30 -14 13 3 -14 6 -31 6 -38 0 -7 5 -10 11 -7 7 5 9 -3 7 -21 -4
                    -36 -39 -44 -71 -17 -21 18 -21 18 -7 -1 13 -17 13 -19 0 -14 -10 4 -14 16
                    -12 42 2 21 -2 36 -8 36 -12 0 -24 29 -34 83 -7 35 -5 37 19 37 16 0 25 -6 25
                    -16 0 -8 5 -12 10 -9 13 8 13 -11 -1 -37 -7 -13 -7 -18 -1 -14 6 3 12 -1 14
                    -10 3 -14 5 -13 21 4 14 15 15 22 6 28 -7 4 -10 3 -5 -4 3 -6 1 -14 -5 -17 -7
                    -5 -10 0 -7 11 2 11 11 18 22 18 16 -2 16 -1 1 16 -11 12 -14 26 -10 41 6 18
                    4 21 -11 15 -10 -4 -21 -1 -26 6 -6 10 -8 8 -8 -4z"/>
                    <path d="M795 856 c-8 -41 0 -57 70 -136 25 -28 43 -55 40 -59 -2 -5 4 -11 15
                    -14 28 -7 25 -32 -6 -56 -15 -12 -23 -21 -19 -21 4 0 2 -6 -4 -14 -15 -18
                    -166 -12 -155 6 3 4 -4 12 -15 17 -15 9 -20 7 -24 -10 -5 -19 -11 -21 -51 -15
                    -37 6 -46 4 -46 -8 0 -17 -48 -30 -87 -22 -16 2 -45 7 -66 11 -32 5 -38 10
                    -34 26 3 10 2 21 -2 24 -4 2 -8 -1 -9 -8 -4 -47 -11 -72 -20 -72 -6 0 -14 -3
                    -18 -8 -4 -4 -2 -7 4 -7 7 0 12 -10 13 -22 1 -22 1 -22 9 2 8 24 8 24 9 3 1
                    -13 6 -23 12 -23 6 0 8 8 4 19 -4 14 -1 18 11 17 9 0 19 6 21 14 4 12 3 13 -5
                    1 -11 -16 -32 -7 -32 14 0 10 9 12 38 7 20 -4 63 -7 95 -7 41 0 57 -4 57 -13
                    0 -13 36 -71 51 -82 13 -10 57 -106 69 -149 6 -24 13 -41 15 -39 7 7 -16 81
                    -41 131 -14 26 -22 52 -19 57 4 6 0 10 -7 10 -8 0 -22 14 -31 30 -19 31 -9 42
                    13 15 7 -9 20 -13 28 -10 8 3 21 0 28 -6 8 -6 14 -9 14 -5 0 18 50 -29 59 -56
                    16 -47 13 -56 -10 -28 l-21 25 17 -28 c9 -16 14 -35 11 -42 -3 -8 -1 -17 5
                    -21 21 -13 59 -85 59 -113 0 -16 4 -32 9 -35 13 -8 88 99 112 162 33 86 38
                    229 10 320 -22 75 -79 167 -135 221 l-35 34 -6 -37z"/>
                </g>
            </svg>
        </React.Fragment>
    )
}

export default Logo;