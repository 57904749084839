import React from 'react';
import { Helmet } from 'react-helmet';
import { CSSTransitionGroup } from 'react-transition-group';
import SliderGallery from '../components/slider-gallery';
import dictionary from '../shared/dictionary';

const Gallery = () => {
	const pageTitle = dictionary.PAGES.GALLERY.TITLE;

	return (
		<React.Fragment>
			<Helmet>
				<title>{pageTitle + dictionary.PAGES.PREFIX}</title>
			</Helmet>
			<CSSTransitionGroup
				transitionName="location-cards"
				transitionAppear={true}
				transitionAppearTimeout={400}
				transitionEnterTimeout={400}
				transitionLeaveTimeout={400}
				className="transition-wrapper"
			>
				<div className="container container--center-v">
					<SliderGallery></SliderGallery>
				</div>
			</CSSTransitionGroup>
		</React.Fragment>
	)
}

export default Gallery;