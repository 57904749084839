const dictionary = {
    SOCIAL_LINKS: [
        {
            TITLE: 'facebook',
            URL: 'https://www.facebook.com/theugliestwife'
        },
        {
            TITLE: 'instagram',
            URL: 'https://instagram.com/theugliestwife'
        }
    ],
    FORM: {
        SEND_LABEL: 'Send',
        VALIDATION_ERROR_MESSAGE: 'Please fill all fields correctly!',
        VALIDATION_ERROR_IMAGE_TOO_BIG: 'The attached image is too big',
        VALIDATION_SUCCESS_MESSAGE: 'Message sent. Thank you!',
    },
    COOKIE_CONSENT: {
        TEXT: 'This website uses cookies to enhance the user experience.',
        BUTTON_TEXT: 'I understand'
    },
    PAGES: {
        PREFIX: ' - theugliestwife',
        ABOUT: {
            TITLE: 'About',
            TEXT: '<span class="color-primary">theugliestwife</span> aka Kasia Piekarska is an artist based in Poland. She was raised surrounded by many kinds of professional theater dolls. Observing her mother, theater prop maker, she developed her skill in traditional forms of media - drawing, painting, sewing and costume designing. By profession she became beautician and makeup artist which helped her to expand her path in art and love for dolls. She started working as a professional doll makeup and tattoo artist. She works on resin and porcelain dolls since 2013.</br></br>Kasia loves tea, winter and history of costume. Nature, scents, colors and sounds around are her biggest inspirations. She likes to spend her free time reading, knitting and watching Woody Allen movies.'
        },
        COMMISSIONS: {
            TITLE: 'Commissions',
            TABS: [
                'Conditions',
                'Service',
                'Order form'
            ],
            INNER_TABS: [
				'Legal disclaimer',
				'Working system',
				'Shipping'
			],
            SERVICE_TAB: [
                {
                    CONTENT: '1/6 YOSD, 12" vinyl doll (Monster High, Barbie etc.)',
                    PRICE: '210€'
                },
                {
                    CONTENT: '1/4 MSD, 1/3 SD, 17" vinyl doll (17" Tonner, Disney Doll etc.)',
                    PRICE: '230€'
                },
                {
                    CONTENT: 'Art doll (Popovy Sisters, Enchanted Doll, etc.)',
                    PRICE: '260€'
                },
                {
                    CONTENT: 'Realistic portrait/human inspired makeup of any size',
                    PRICE: '260€'
                },
                {
                    CONTENT: 'Tan/dark resin',
                    PRICE: '30€'
                },
                {
                    CONTENT: 'Full body blushing',
                    PRICE: '+ 170€'
                },
                {
                    CONTENT: 'Hands/feet makeup only',
                    PRICE: '60€ (both 100€)'
                },
                {
                    CONTENT: 'Eyelashes',
                    PRICE: '5€'
                },
                {
                    CONTENT: 'Faceup removal (including sanding on plastic dolls)',
                    PRICE: '15€'
                },
                'Tattoos/fantasy painting - <strong>contact me for a quote!</strong>',
                'Freckles, face skin texturing/veins, shimmer & glitter are <strong>FREE!</strong>'
            ],
            LEGAL_DISCLAIMER_TAB: [
                'Conterfeit/recasted dolls aren\'t accepted. Legit dolls are allowed only',
                'Contact information and billing address will be used to communicate with the client about the order and for legal reasons, like paying taxes, only',
                'By commissioning theugliestwife you agree and accept to all the rules and conditions written on the website www.theugliestwife.com'
            ],
            WORKING_SYSTEM_TAB: [
                'Coping of other artists work/style is not accepted',
                'Partial faceups, eye opening, wigs, reroots, outfit or accessories making aren\'t offered',
                'Commission request can be sent at Instagram/Facebook or at <a href="mailto:theugliestwife@gmail.com">theugliestwife@gmail.com</a> directly',
                'Working on a single commission depends on order\'s place on the waiting list and project complexity. Waiting time is 1-2 months approximately. An extra time is needed in case of any personal issues, illness, weather conditions etc.',
                'Payments are accepted by PayPal only. The artist contacts the client with payment info before starting the project. Work is not continued/finished until full/partial payment is not complete',
                'Work in progress photos are send by client\'s request only and include the finished look, before the final protecting coats of the sealer. At this stage small add-ons can be accepted only',
                'Faceup changes aren\'t accepted when the work is done. If the project requires an exact color or design, attaching inspiration photos with precise examples is required. Choosing faceup artist carefully and making sure to accept the artist style before sending commission request is highly recommended',
                'If the old faceup hasn\'t been removed well enough, an extra fee can be charged to clean it properly. The artist is not responsible if doll will have any stains/imperfecions in case if they have to remove its faceup',
                'Hand painted doll is an artwork not a children\'s toy and should be treated with special care. Touching it is not recommended, because it may chip/scratch and get dirty. The doll should be keeped away from water, extreme temperatures and excessive humidity. Using gloves is recommended, since bare human hands are naturally oily and can damage the faceup'
            ],
            SHIPPING_TAB: [
                'The doll should be carefully packed to avoid faceup damages while in transit. The artist is not responsible for loss or damage of contents (doll, faceup etc.) after the parcel is send out',
                'Parcels outside EU with value over 20€ may be charged by customs. To avoid a customs fee, marking the parcel as \'gift\' and put value no higher than 20€ is recommended. Parcels with high insurance may be value higher by the Post Office, making sure about the final value of the package is recommended. Parcels are sended back marked as \'gift\' with 20€, unless the client requested higher value or/and an extra insurance on their responsibility',
                'Parcels are sent by Polish Post or by private couriers (DPD, UPS, FedEx etc.). All shipping options and rates can be provided at customer\'s request'
            ],
            FORM_CONTENT: [
                {
                    "title": "Doll makeup details",
                    "fieldNames": [
                        {
                            "name": "Company/Name/Size"
                        },
                        {
                            "name": "Gender",
                            "inputType": "dropdown",
                            "options": [
                                "Male",
                                "Female",
                                "Other"
                            ]
                        },
                        {
                            "name": "Mood/Expression/Character"
                        },
                        {
                            "name": "Hair & eyebrows color"
                        },
                        {
                            "name": "Lip color"
                        },
                        {
                            "name": "Lip finish",
                            "inputType": "dropdown",
                            "options": [
                                "Matt",
                                "Gloss"
                            ]
                        },
                        {
                            "name": "Blush",
                            "inputType": "dropdown",
                            "options": [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            "name": "Freckles",
                            "inputType": "dropdown",
                            "options": [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            "name": "Eye color"
                        },
                        {
                            "name": "Photo references are welcome!",
                            "inputType": "file",
                            "conditions": [
                                "only image files are allowed",
                                "maximum file size is 2MB"
                            ]
                        }
                    ]
                },
                {
                    "title": "Additions",
                    "fieldNames": [
                        {
                            "name": "Shimmer",
                            "inputType": "dropdown",
                            "options": [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            "name": "Glitter on the eyelids/tattoos",
                            "inputType": "dropdown",
                            "options": [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            "name": "Black eyelashes",
                            "inputType": "dropdown",
                            "options": [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            "name": "Previous faceup removal",
                            "inputType": "dropdown",
                            "options": [
                                "Yes",
                                "No"
                            ]
                        },
                        {
                            "name": "Manicure/Pedicure",
                            "inputType": "dropdown",
                            "options": [
                                "Manicure",
                                "Pedicure",
                                "Both",
                                "None"
                            ]
                        },
                        {
                            "name": "Nail color"
                        },
                        {
                            "name": "Full body blushing",
                            "inputType": "dropdown",
                            "options": [
                                "Yes",
                                "No"
                            ]
                        }
                    ]
                },
                {
                    "title": "Shipping info",
                    "fieldNames": [
                        {
                            "name": "Email"
                        },
                        {
                            "name": "Full name"
                        },
                        {
                            "name": "Address"
                        },
                        {
                            "name": "Postcode/City"
                        },
                        {
                            "name": "Country"
                        },
                        {
                            "name": "Phone"
                        },
                        {
                            "name": "Value of contents to be marked on customs forms"
                        },
                        {
                            "name": "Parcel includes"
                        },
                        {
                            "name": "Where did you find out about theugliestwife?",
                            "emoji": "0x1F60A"
                        }
                    ]
                }
            ]
        },
        CONTACT: {
            TITLE: 'Contact',
            TEXT: 'If you have any questions, don\'t hesitate to ask, I will answer with pleasure. Thank you for your interest!',
            FORM_CONTENT: {
                NAME: 'Name',
                EMAIL: 'Email',
                MESSAGE: 'Message'
            },
        },
        GALLERY: {
            TITLE: 'Gallery'
        },
        HOME: {
            TITLE: 'Home',
            HEADER: 'THEUGLIESTWIFE',
            TEXT: 'doll customizer & makeover artist'
        },
        NOT_FOUND: {
            TITLE: '404',
            TEXT: 'Page not found!'
        }
    }
}

export default dictionary;