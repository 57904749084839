import React from 'react';

type Props = {
    listItemContent: { [key: string]: string }
};

const CommissionListItem = (props: Props) => (
    <li
        className="commissions__list-item"
    >
        {props.listItemContent.CONTENT || props.listItemContent.PRICE ? (
            <>
                <span>&#9656; {props.listItemContent.CONTENT}</span>
                <span>{props.listItemContent.PRICE}</span>
            </>
        ) : (
            <span
                dangerouslySetInnerHTML={{
                    __html: `&#9656; ${props.listItemContent}`
                }}
            />
        )}
    </li>
);

export default CommissionListItem;